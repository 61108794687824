<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>项目名称：</span>
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请输入或选择项目名称"
                class="ipt_width project_width"
                @change="dataUpdate"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="search_center">
              <span>实际开始时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="实际开始时间（始）"
                end-placeholder="实际开始时间（末）"
              >
              </el-date-picker>
            </div> -->

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">申请流程</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 {{ `(${this.statistics.undetermind})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审批中 {{ `(${this.statistics.approval})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 {{ `(${this.statistics.approvalReject})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已审核 {{ `(${this.statistics.approvalPass})` }}</span>
            </el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            :key="Math.random()"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" width="50" type="index" label="序号"></el-table-column>

            <el-table-column
              align="center"
              prop="projectName"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              label="项目名称"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              min-width="110"
              prop="projectSource"
              label="项目来源"
            >
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectSource) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              min-width="110"
              prop="businessLines"
              label="业务种类"
            >
              <template slot-scope="scope">
                {{ scope.row.businessLines | dict(dictData.businessLines) }}
              </template></el-table-column
            >

            <el-table-column
              align="center"
              min-width="130"
              sortable
              prop="taskDate"
              label="填报时间"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              min-width="150"
              prop="statusName"
              label="方案进度"
            >
            </el-table-column>

            <el-table-column prop="title" width="200" align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  class="text_Grant_Bgc"
                  @click.stop="add(scope.row)"
                  v-if="!scope.row.id"
                  >填报</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  class="text_Edit_Bgc"
                  @click.stop="edit(scope.row)"
                  v-if="scope.row.id && (scope.row.status == 0 || scope.row.status == 5)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  v-if="scope.row.id"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog width="75%" title="生产绩效分配方案流程图" :visible.sync="dialogVisible">
      <div style="padding: 5px">
        <img src="@/assets/生产绩效分配方案流程图.png" alt="生产绩效分配方案流程图" width="100%" />
      </div>
    </el-dialog>

    <Edit
      :isShow.sync="showEditDialog"
      :getTitle="getTitle"
      v-if="showEditDialog"
      :options="form"
    ></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        projectId: '',
        pageNow: 1,
        pageSize: 50,
        total: 0,
        applyType: 'UNDETERMINED', //1未审核，2审核中，3已审批
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      projectList: [], // 项目数据
      dialogVisible: false,
      createdDate: [],
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        approvalPass: 0,
      },
      getTitle: '',
      dictData: {
        projectSource: [],
        businessLines: [],
      },
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  watch: {
    'parameter.applyType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },

  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getProjectList() /** 加载 "项目" 数据 **/
    this.getData()
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_LINES', true)
      .then(res => {
        this.dictData.businessLines = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    getData() {
      // if (this.createdDate && this.createdDate != []) {
      //   this.parameter.startDate = new Date(this.createdDate[0]).getTime()
      //   this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      // } else if (this.createdDate == null) {
      //   this.parameter.startDate = ''
      //   this.parameter.endDate = ''
      // }
      this.loading = true
      this.$api.projectPerformance
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.getApplyStatistics()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 点击填报
    add(row) {
      this.getTitle = '绩效分配方案填报'
      this.form = {
        //lsit_s为1表示填报，2表示编辑， 3表示可撤回详情，4表示明细详情不可撤回
        list_s: 1,
        ...row,
      }
      this.showEditDialog = true
    },
    edit(row) {
      this.getTitle = '绩效分配方案编辑'
      this.form = row.deepClone()
      this.form.list_s = 2
      this.showEditDialog = true
    },
    // 点击详情
    detail(row) {
      this.getTitle = '绩效分配方案详情'
      this.form = row.deepClone()
      this.form.list_s = 3
      this.showEditDialog = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEditDialog = false
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.projectId = ''
      // this.parameter.startDate = ''
      // this.parameter.endDate = ''
      this.parameter.pageNow = 1
      this.createdDate = []
      this.getData() /** 加载  数据 **/
    },

    /** 加载 "项目" 数据 **/
    getProjectList() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },

    getApplyStatistics() {
      this.$api.projectPerformance
        .getApplyStatistics()
        .then(res => {
          if (res.data) {
            res.data.forEach(v => {
              if (v.applyType == 'UNDETERMINED') {
                this.statistics.undetermind = v.sumQuantity
              } else if (v.applyType == 'APPROVAL') {
                this.statistics.approval = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_REJECT') {
                this.statistics.approvalReject = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_PASS') {
                this.statistics.approvalPass = v.sumQuantity
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
